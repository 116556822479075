<template>
	<div class="checkout-layout py-50">
		<div class="text-center container">
			<v-stepper :active-index="activeIndex" :steps="steps" />
		</div>

		<div class="mt-50 container-xl">
			<slot />
		</div>
	</div>
</template>

<script lang="ts" setup>
	const route = useRoute()

	const paths: Record<string, number> = {
		checkout: 0,
		shipping: 1,
		payment: 2,
		complete: 3,
	}

	const steps = [
		{ title: 'Welcome' },
		{ title: 'Shipping' },
		{ title: 'Payment' },
		{ title: 'Complete' },
	]

	const activeIndex = computed(() => {
		const key = route.path.split('/').pop()
		return key && key in paths ? paths[key] : 0
	})
</script>

<style lang="scss">
	.checkout-layout {
		@include gradient();
		height: 100%;
	}
</style>
