<template>
	<nav aria-label="Stepper" class="stepper">
		<v-stepper-btn
			v-for="(step, i) in steps"
			:key="i"
			:active-index="activeIndex"
			:index="i"
			:title="step.title"
			@click="onclick(step)" />
	</nav>
</template>

<script lang="ts" setup>
	const props = defineProps<{
		activeIndex?: number
		steps: Step[]
	}>()

	const activeIndex = toRef(props, 'activeIndex', 0)

	function onclick(step: Step) {
		if (step.onClick) {
			step.onClick()
		}
	}
</script>

<script lang="ts">
	export interface Step {
		title: string
		onClick?: () => void
	}
</script>

<style lang="scss">
	@layer components {
		.stepper {
			@include media-breakpoint-up('lg') {
				display: inline-flex;
				flex-direction: row;
			}
		}
	}
</style>
